import React from "react";
import Navbar from "../components/navbar";
import HeroImage from "../components/hero-image";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';

function Hero() {
    return <div className="pa4 pa5-l white min-vh-100-l flex flex-column" id="hero">
        <div>
            <Navbar />
        </div>
        <div className="flex-grow-1 pv4 tc flex flex-column align-center">
            <div className="flex-grow-1"></div>
            <div className="overflow-hidden">
                <h1 className="white f3 f2-m f1-l pb0 mb2 mb0-ns">FrameCoders</h1>
                <h2 className="f6 f4-l lh-copy ttu light-purple pt1 mt0">
                    <span>We dream in code &amp; code the dreams</span>
                </h2>
                <HeroImage />
            </div>
            <div className="flex-grow-1"></div>
        </div>
        <div className="center overflow-hidden">
            <Arrow onClick={() => scrollTo('#services')} />
        </div>
    </div>
}

export default Hero;