import React from "react";
import ReactIcon from "../images/react-icon.svg";
import VueIcon from "../images/vue-icon.svg";
import PhpIcon from "../images/php-icon.svg";
import PythonIcon from "../images/python-icon.svg";
import ErpIcon from "../images/erp-icon.svg";
import LaravelIcon from "../images/laravel-icon.svg";
import Fade from 'react-reveal/Fade';

function Stack() {
    return <div className="flex flex-wrap black-50 fw7 items-end">
            <div className="w-50 w-third-ns pa3 mb4-l">
                <Fade bottom delay={200}>
                    <ReactIcon />
                    <p className="mt4">React</p>
                </Fade>
            </div>
            <div className="w-50 w-third-ns pa3 mb4-l">
                    <Fade bottom delay={400}>
                        <VueIcon />
                        <p className="mt4">Vue</p>
                    </Fade>
            </div>
            <div className="w-50 w-third-ns pa3 mb4-l">
                <Fade bottom delay={600}>
                    <PhpIcon />
                    <p className="mt4">PHP</p>
                </Fade>
            </div>
            <div className="w-50 w-third-ns pa3 mb4-l">
                <Fade bottom delay={800}>
                    <PythonIcon />
                    <p className="mt4">Python</p>
                </Fade>
            </div>
            <div className="w-50 w-third-ns pa3 mb4-l">
                <Fade bottom delay={1000}>
                    <ErpIcon />
                    <p className="mt4">ERP.NEXT</p>
                </Fade>
            </div>
            <div className="w-50 w-third-ns pa3 mb4-l">
                <Fade bottom delay={1200}>
                    <LaravelIcon />
                    <p className="mt4">Laravel</p>
                </Fade>
            </div>
    </div>
}

export default Stack;