import React from "react";
import Stack from "../components/stack";
import Card from "../components/card";
import Heading from "../components/heading";
import DarkArrow from "../components/dark-arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';

function Services() {
    return <div className="bg-light-gray pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-100 relative" id="services" style={{
        boxShadow: "0 0px 60px 0 rgba(0, 0, 0, 0.6)"
    }}>
        <div>
            <div class="mw9 center">
                <Heading no="01" title="What we do" description="The cutting edge technology stack at your disposal" />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="pa3 pa5-l tc">
            <div class="mw9 center flex-l">
                <div className="w-100-m w-100-l mb5 mb0-l pa4-l pr5-l">
                    <Stack />
                </div>
                <div className="w-100-l">
                    <Card>
                        <article>
                            <h3 className="fw7 f3 f2-l pa0 mt0 mb3">Our services</h3>
                            <div className="bg-white mb5" style={{
                                width: "50px",
                                height: "1px",
                            }}></div>
                            <p className="white-70 fw7 f5 f4-l mb3">Web development</p>
                            <p className="white-70 fw7 f5 f4-l mb3">Software engineering</p>
                            <p className="white-70 fw7 f5 f4-l mb3">Team management</p>
                            <p className="white-70 fw7 f5 f4-l mt5">&amp; others</p>
                        </article>
                    </Card>
                </div>
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="center mt4 mt0-l">
            <DarkArrow onClick={() => scrollTo('#about')} />
        </div>
    </div>
}

export default Services;