import React from "react";
import Heading from "../components/heading";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';
import Card from "../components/card";
import MailIcon from "../images/mail.svg";
import GradientArrow from "../images/gradient-arrow.svg";
import Flip from 'react-reveal/Flip';

function Contact() {
    return <div className="pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-100 relative" id="contact">
        <div>
            <div class="mw9 center">
                <Heading no="03" title="Get in touch" description="Whether you have a project or want to apply for a job, we're here for you" variant="dark" />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="pa3 pa4-l tc">
            <div class="mw9 center flex-l">
                <div className="w-100-m w-100-l mb5 mb0-l pa3-l pr6-l">
                    <Card>
                        <article className="lh-copy">
                            <h3 className="fw7 f4 f3-l pa0 mt0 mb3">Leave a message, we'll reply shortly</h3>
                            <div className="bg-white mb5" style={{
                                width: "50px",
                                height: "1px",
                            }}></div>
                            <p className="white-70 fw7 f6 f5-l mb3">
                                We craft software 100% remotely and get the job done.
                            </p>
                            <p className="white-70 fw7 f6 f5-l mb3">
                                Help us make your dreams come true
                            </p>
                            <a href="mailto:office@framecoders.com" className="no-underline">
                                <div className="mt5 ph4 pt3 pb3 pb2-l bg-gradient-gray-white black-80 fw7 f7 f5-l flex br2 ba b--white bw1 align-center justify-center no-underline" style={{
                                    boxShadow: "0 3px 7px 0 rgba(0,0,0, 0.5)",
                                    maxWidth: "380px",
                                    backgroundColor: "#dfdfdf"
                                }}>
                                    <div className="pt1 pb1 dn dib-l">
                                        <MailIcon />
                                    </div>
                                    <div class="flex-grow-1 tc ph3">
                                        office@framecoders.com
                                    </div>
                                </div>
                            </a>
                        </article>
                    </Card>
                    <div className="mt5 tl ph3 lh-copy">
                        <h3 className="f4 white fw7 mb0">Couldn't find a suitable position?</h3>
                        <p class="fw7 white-30">Apply anyways! We' try to figure it out.</p>
                    </div>
                </div>
                <div className="w-100-l tl mb5 pt4 pt0-l">
                    <h3 className="white fw7 f3 f2-l mb0 pb0">Join our remote team</h3>
                    <p className="white-50 db mb5">Open positions:</p>
                    <Flip bottom cascade>
                        <div className="fw7 f5 f4-l white-40 pa3 flex dim mw6">
                            <div class="flex-grow-1 lh-copy">Senior React Developer</div>
                            <div className="dn">
                                <GradientArrow />
                            </div>
                        </div>
                        <div className="fw7 f5 f4-l white-40 pa3 flex dim mw6">
                            <div class="flex-grow-1 lh-copy">Senior PHP Developer</div>
                            <div className="dn">
                                <GradientArrow />
                            </div>
                        </div>
                        <div className="fw7 f5 f4-l white-40 pa3 flex dim mw6">
                            <div class="flex-grow-1 lh-copy">Senior Vue Developer</div>
                            <div className="dn">
                                <GradientArrow />
                            </div>
                        </div>
                        <div className="fw7 f5 f4-l white-40 pa3 flex dim mw6">
                            <div class="flex-grow-1 lh-copy">Senior Python Developer</div>
                            <div className="dn">
                                <GradientArrow />
                            </div>
                        </div>
                    </Flip>
                </div>
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="center">
            <Arrow onClick={() => scrollTo('#hero')} className="rotate-180" />
        </div>
    </div>
}

export default Contact;