import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Fade from 'react-reveal/Fade';

function Avatars({ name }) {
    const data = useStaticQuery(graphql`
    {
        karol: file(relativePath: { eq: "karol.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        jakub: file(relativePath: { eq: "jakub.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        lukasz: file(relativePath: { eq: "lukasz.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        adam: file(relativePath: { eq: "adam.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        krzysztof: file(relativePath: { eq: "krzysztof.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        you: file(relativePath: { eq: "others.png" }) {
            childImageSharp {
                fixed(width: 138, height: 138, quality: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }`);

    return <div className="flex flex-wrap">
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb5-l">
            <Fade top delay={200}>
                <Img fixed={data.karol.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Karol</h3>
                <p className="white-60 ph2 f6 mt1 mb2">CEO &amp; Co-Founder</p>
            </Fade>
        </div>
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb5-l">
            <Fade top delay={400}>
                <Img fixed={data.jakub.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Jakub</h3>
                <p className="white-60 ph2 f6 mt1 mb2">Co-Founder</p>
            </Fade>
        </div>
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb5-l">
            <Fade top delay={800}>
                <Img fixed={data.krzysztof.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Krzysztof</h3>
                <p className="white-60 ph2 f6 mt1 mb2">Co-Founder</p>
            </Fade>
        </div>
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb0-l pl4-l">
            <Fade top delay={600}>
                <Img fixed={data.lukasz.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Łukasz</h3>
                <p className="white-60 ph2 f6 mt1 mb2">Head of department</p>
            </Fade>
        </div>
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb0-l pl4-l">
            <Fade top delay={1000}>
                <Img fixed={data.adam.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Adam</h3>
                <p className="white-60 ph2 f6 mt1 mb2">Head of department</p>
            </Fade>
        </div>
        <div className="w-100 w-50-m w-third-l tc tl-ns mb4 mb0-l pl4-l">
            <Fade top delay={1200}>
                <Img fixed={data.you.childImageSharp.fixed} />
                <h3 className="fw7 white f4 ph2 mt2 mb0 pb0">Our Remote Team</h3>
                <p className="white-50 ph2 f6 mt1">Best designers &amp; developers</p>
            </Fade>
        </div>
    </div>
}

export default Avatars;