import React from "react";
import Logo from "./logo";
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';

function Navbar() {
    return <div className="mw9 center flex-l f6">
        <div className="tc tl-l">
            <Logo />
        </div>
        <div className="flex-grow-1"></div>
        <div>
            <ul className="ttu list fw7">
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#services')}>What we do</li>
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#about')}>Who we are</li>
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#contact')}>Get in touch</li>
                <li className="dn ml4">
                    <Link to="/" className="white-90 link ba b--purple bw1 pv2 ph4 br-pill">Blog</Link>
                </li>
            </ul>
        </div>
    </div>
}

export default Navbar;