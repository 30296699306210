import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Hero, Services, About, Contact, Footer } from "../sections";

const IndexPage = () => (
  <Layout>
    <SEO title="Best developers and designers" />
    <Hero />
    <Services />
    <About />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage