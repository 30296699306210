import React from "react";
import Heading from "../components/heading";
import Avatars from "../components/avatars";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';

function About() {
    return <div className="bg-gradient-blue-purple pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-10" id="about">
        <div>
            <div class="mw9 center">
                <Heading no="02" title="Who we are" description="The team of passionate craftsmen ready to work with you" variant="white" />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="pa3 pa5-l tc">
            <div class="mw8 center">
                <Avatars />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="center">
            <Arrow onClick={() => scrollTo('#contact')} />
        </div>
    </div>
}

export default About;